import { getImage } from 'gatsby-plugin-image'

import EbookForm from '~/components/forms/EbookForm'
import MainLayout from '~/components/layouts/MainLayout'
import LazyHydrate from '~/components/shared/LazyHydrate'
import ReCaptchaProvider from '~/components/shared/ReCaptchaProvider'
import { fileToImageLikeData } from '~/utils'
import InfoSectionLeadMagnets from '~/views/EbookLandings/components/InfoSectionLeadMagnets'
import PrimeSectionLeadMagnets from '~/views/EbookLandings/components/PrimeSectionLeadMagnets'
import useCustomMarketplaceChecklistStaticQuery from '~/views/EbookLandings/CustomMarketplaceChecklist/useCustomMarketplaceChecklistStaticQuery'

const dataList = [
  'Simply outlined steps on creating a custom marketplace platform',
  'Convenient printable document format',
  'Check the steps you completed and move forward.',
]

const CustomMarketplaceChecklist = () => {
  const query = useCustomMarketplaceChecklistStaticQuery()
  const imageEbook = getImage(fileToImageLikeData(query.imageEbook))

  return (
    <MainLayout isTransparent>
      <PrimeSectionLeadMagnets
        title="Free essential checklist on developing a marketplace website"
        description="Are you inspired by the success of Airbnb, Amazon and Fiverr? Do you want to build your own custom marketplace, but don’t know where to start?"
        maxWidthTitle="870px"
        maxWidthDescription="800px"
      />

      <InfoSectionLeadMagnets
        imageEbook={imageEbook}
        imageClass="customMarketplaceChecklist"
        imageMeta="Custom Marketplace Development Checklist | Codica"
        description="Our expert team has created a free step-by-step checklist for building your custom marketplace. It will help you define your overall strategy, set goals and complete them, and easily follow the progress."
        dataList={dataList}
      />
      <LazyHydrate whenVisible>
        <ReCaptchaProvider>
          <EbookForm
            dataId="downloadChecklistButton"
            formTitle="Download our free checklist and start acting now!"
            fileName="Custom Marketplace Development Checklist"
            urlPath="thank-you-custom-marketplace-development-checklist"
          />
        </ReCaptchaProvider>
      </LazyHydrate>
    </MainLayout>
  )
}

export default CustomMarketplaceChecklist
