import { graphql, useStaticQuery } from 'gatsby'

import { PhotosQuery } from '~/types/graphql'

const useCustomMarketplaceChecklistStaticQuery = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      imageEbook: file(
        relativePath: { eq: "ebook/custom-marketplace-checklist.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  return query
}

export default useCustomMarketplaceChecklistStaticQuery
